import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import { Copy, Info } from "lucide-react";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import axios from "../../../apis/thresource";
import { getRequestHeadersFromAuth } from "../../../utils/Utils";
import toast from "react-hot-toast";

// Define the validation schema
const schema = yup.object().shape({
  className: yup.string().required("Class Name is required"),
  voucher_code: yup.string().required("Voucher Code is required"),
  title: yup.string().required("School Name is required"),
  address_line_1: yup.string().required("Address line 1 is required"),
  address_line_2: yup.string().required("Address line 2 is required"),
  city: yup.string().required("City is required"),
  county: yup.string().required("County is required"),
  postcode: yup.string().required("Postcode is required"),
  country: yup.string().required("Country is required"),
});

export default function SchoolTab({ user, requestAddSchool, auth, history }) {
  const {
    register,
    getValues,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm({
    resolver: yupResolver(schema),
  });

  const onSubmit = (data) => {
    console.log(data);
    requestAddSchool(data);
  };

  const getTeacherSchool = async () => {
    return axios.post(
      "/api/Schools/GetSchoolForUser",
      { user_id: user?.id },
      getRequestHeadersFromAuth(auth)
    );
  };

  useEffect(() => {
    const fetchTeacherSchool = async () => {
      const response = await getTeacherSchool();
      console.log("response school: ", response);
      if (response?.status === 200) {
        console.log("response?.data?.data: ", response?.data?.data);
        setValue("name", response?.data?.data?.name);
        setValue("address_line_1", response?.data?.data?.address_line_1);
        setValue("address_line_2", response?.data?.data?.address_line_2);
        setValue("city", response?.data?.data?.city);
        setValue("county", response?.data?.data?.county);
        setValue("postcode", response?.data?.data?.postcode);
        setValue("country", response?.data?.data?.country);
        setValue("className", response?.data?.data?.class_name);
        setValue("voucher_code", response?.data?.data?.voucher_code);
      }
    };
    fetchTeacherSchool();
  }, []);

  return (
    <div>
      <form onSubmit={handleSubmit(onSubmit)} className="space-y-6">
        <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
          <div className="space-y-6">
            <div>
              <h2 className="text-2xl font-semibold mb-2">Class Name</h2>
              <div>
                <label
                  htmlFor="className"
                  className="block text-[14px] font-medium text-purple-200 mb-1"
                >
                  Class Name
                </label>
                <input
                  type="text"
                  id="className"
                  {...register("className")}
                  className="w-full text-sm px-3 py-2 border border-gray-300 rounded-md pr-10"
                  placeholder="Class Name"
                />
                {errors.className && (
                  <p className="text-red-600 text-xs mt-1">
                    {errors.className.message}
                  </p>
                )}
              </div>
            </div>

            <div>
              <h2 className="text-2xl font-semibold mb-2">Redeem FREE books</h2>
              <div className="relative">
                <input
                  type="text"
                  id="voucher_code"
                  {...register("voucher_code")}
                  className="w-full px-3 py-2 border border-gray-300 rounded-md pr-10"
                  placeholder="Voucher Code"
                  readOnly
                />
                <Copy
                  onClick={() => {
                    navigator.clipboard.writeText(getValues("voucher_code"));
                    toast.success("Voucher code copied to clipboard");
                  }}
                  className="w-5 h-5 text-gray-400 absolute right-3 top-1/2 transform -translate-y-1/2 cursor-pointer"
                />
                {errors.voucher_code && (
                  <p className="text-red-600 text-xs mt-1">
                    {errors.voucher_code.message}
                  </p>
                )}
              </div>
              <p className="text-[12px] text-gray-900 mt-1">
                Use this voucher code to redeem your FREE books at the basket.
              </p>
            </div>

            <div className="!bg-white-100 p-4 rounded-md shadow-md">
              <div className="flex items-start">
                <Info className="w-10 h-10 text-purple-200 mr-2 " />
                <div>
                  <h3 className="font-semibold text-2xl">Delivery Address</h3>
                  <p className="text-[12px] text-gray-900">
                    All orders will be delivered to the registered school
                    address. You cannot change this address without approval.
                    Please{" "}
                    <a href="#" className="text-purple-600 underline">
                      contact us
                    </a>{" "}
                    if the school address needs to be updated or changed.
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div>
            <h2 className="text-2xl font-semibold mb-2">School Address</h2>
            <div className="space-y-4">
              <div>
                <label
                  htmlFor="title"
                  className="block text-[14px] font-medium text-purple-200 mb-1"
                >
                  School Name
                </label>
                <input
                  type="text"
                  id="name"
                  {...register("name")}
                  className="w-full px-3 py-2 border border-gray-300 rounded-md"
                  placeholder="School Name"
                  readOnly
                />
                {errors.name && (
                  <p className="text-red-600 text-sm mt-1">
                    {errors.name.message}
                  </p>
                )}
              </div>
              <div>
                <label
                  htmlFor="address_line_1"
                  className="block text-[14px] font-medium text-purple-200 mb-1"
                >
                  Address line 1
                </label>
                <input
                  type="text"
                  id="address_line_1"
                  {...register("address_line_1")}
                  className="w-full px-3 py-2 border border-gray-300 rounded-md"
                  placeholder="Address line 1"
                  readOnly
                />
                {errors.address_line_1 && (
                  <p className="text-red-600 text-sm mt-1">
                    {errors.address_line_1.message}
                  </p>
                )}
              </div>
              <div>
                <label
                  htmlFor="address_line_2"
                  className="block text-[14px] font-medium text-purple-200 mb-1"
                >
                  Address line 2
                </label>
                <input
                  type="text"
                  id="address_line_2"
                  {...register("address_line_2")}
                  className="w-full px-3 py-2 border border-gray-300 rounded-md"
                  placeholder="Address line 2"
                  readOnly
                />
                {errors.address_line_2 && (
                  <p className="text-red-600 text-sm mt-1">
                    {errors.address_line_2.message}
                  </p>
                )}
              </div>
              <div>
                <label
                  htmlFor="city"
                  className="block text-[14px] font-medium text-purple-200 mb-1"
                >
                  City
                </label>
                <input
                  type="text"
                  id="city"
                  {...register("city")}
                  className="w-full px-3 py-2 border border-gray-300 rounded-md"
                  placeholder="City"
                  readOnly
                />
                {errors.city && (
                  <p className="text-red-600 text-sm mt-1">
                    {errors.city.message}
                  </p>
                )}
              </div>
              <div>
                <label
                  htmlFor="county"
                  className="block text-[14px] font-medium text-purple-200 mb-1"
                >
                  County
                </label>
                <input
                  type="text"
                  id="county"
                  {...register("county")}
                  className="w-full px-3 py-2 border border-gray-300 rounded-md"
                  placeholder="County"
                  readOnly
                />
                {errors.county && (
                  <p className="text-red-600 text-sm mt-1">
                    {errors.county.message}
                  </p>
                )}
              </div>
              <div>
                <label
                  htmlFor="postcode"
                  className="block text-[14px] font-medium text-purple-200 mb-1"
                >
                  Postcode
                </label>
                <input
                  type="text"
                  id="postcode"
                  {...register("postcode")}
                  className="w-full px-3 py-2 border border-gray-300 rounded-md"
                  placeholder="Postcode"
                  readOnly
                />
                {errors.postcode && (
                  <p className="text-red-600 text-sm mt-1">
                    {errors.postcode.message}
                  </p>
                )}
              </div>
              <div>
                <label
                  htmlFor="country"
                  className="block text-[14px] font-medium text-purple-200 mb-1"
                >
                  Country
                </label>
                <input
                  type="text"
                  id="country"
                  {...register("country")}
                  className="w-full px-3 py-2 border border-gray-300 rounded-md"
                  placeholder="Country"
                  readOnly
                />
                {errors.country && (
                  <p className="text-red-600 text-xs mt-1">
                    {errors.country.message}
                  </p>
                )}
              </div>
            </div>
          </div>
        </div>

        <hr className="border-t border-gray-300 my-6" />

        <div>
          <button
            type="submit"
            className="h-18 px-6 py-2 bg-purple-200 text-white rounded-full hover:bg-purple-100 transition-colors"
          >
            Save Changes
          </button>
        </div>
      </form>
    </div>
  );
}
