import React, { useState } from "react";
import Spinner from "react-bootstrap/Spinner";

const OrderSummary = ({
  onApplyVoucher = () => {},
  voucherApplied = false,
  isBasketEmpty = false,
  onCheckoutClick = () => {},
  isLoading = false,
  onGetCode = () => {},
}) => {
  const [voucherCode, setVoucherCode] = useState(null);
  const handleVoucherCodeChange = (e) => {
    if (e.target.value === "") {
      setVoucherCode(null);
    } else {
      setVoucherCode(e.target.value);
    }
  };

  return (
    <div className="rounded-lg bg-gray-100 p-6 shadow-sm border border-gray-50">
      <h2 className="text-3xl font-semibold text-black">Order Summary</h2>
      <div className="mt-6">
        <p className="text-gray-900 text-[16px]">
          Enter your <span className="font-medium">School Voucher Code</span> to
          activate your basket and claim your FREE books.
        </p>
        <button
          onClick={onGetCode}
          className="mt-2 text-[14px] font-semibold text-purple-200 hover:text-purple-100 transition-colors"
        >
          Get code
        </button>
      </div>
      <div className="mt-6">
        <label className="text-lg font-medium text-purple-200">
          School Voucher Code
        </label>
        <input
          type="text"
          placeholder="XXX-XXX-XXX"
          value={voucherCode}
          onChange={handleVoucherCodeChange}
          className="mt-1 w-full rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 focus:border-purple-500 focus:outline-none focus:ring-1 focus:ring-purple-500"
        />
      </div>

      <button
        onClick={() => onApplyVoucher(voucherCode)}
        disabled={isBasketEmpty || voucherCode === null || voucherApplied}
        className={`mt-6 w-full h-18 justify-center items-center rounded-full px-4 py-2  text-[16px] transition-colors ${
          isBasketEmpty || voucherCode === null || voucherApplied
            ? "bg-gray-300 text-gray-900 cursor-not-allowed"
            : "bg-purple-200 text-white hover:opacity-80"
        }`}
      >
        {isLoading && (
          <Spinner
            animation="border"
            role="status"
            className="mr-2"
            size="lg mx-4"
          />
        )}
        {voucherApplied ? "Voucher Applied" : "Apply Voucher"}
      </button>

      {voucherApplied && (
        <>
          <hr className="border-t border-gray-300  mt-6" />
          <button
            onClick={onCheckoutClick}
            disabled={isBasketEmpty || !voucherApplied}
            className={`w-full h-18 justify-center items-center rounded-full px-4 py-2 text-[16px] transition-colors ${
              isBasketEmpty
                ? "bg-gray-300 text-gray-900 cursor-not-allowed"
                : "bg-purple-200 text-white hover:opacity-80"
            }`}
          >
            Continue to Checkout
          </button>
        </>
      )}
    </div>
  );
};

export default OrderSummary;
