import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { Lock } from "lucide-react";
import { Link } from "react-router-dom";
import axios from "../../apis/thresource";
import toast from "react-hot-toast";
import Spinner from "react-bootstrap/Spinner";
import { useState, useEffect } from "react";

const schema = yup
  .object({
    first_name: yup.string().required("First name is required"),
    last_name: yup.string().required("Last name is required"),
    email: yup.string().email("Invalid email").required("Email is required"),
    mobile: yup.string().required("Phone number is required"),
    password: yup
      .string()
      .min(8, "Password must be at least 8 characters")
      .matches(/[A-Z]/, "Password must contain an uppercase letter")
      .matches(/[0-9]/, "Password must contain a number")
      .required("Password is required"),
    confirmPassword: yup
      .string()
      .oneOf([yup.ref("password")], "Passwords must match")
      .required("Please confirm your password"),
    enable_2fa: yup.boolean(),
  })
  .required();

export default function RegisterAccount({
  onUpdateUser,
  onHideHeader,
  history,
}) {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const [isLoading, setIsLoading] = useState(false);

  console.log(errors);

  const onSubmit = (data) => {
    console.log(data);
    setIsLoading(true);
    const payload = {
      ...data,
      teacher: true,
      opt_in_marketing: true,
      username: data.email,
    };
    const { confirmPassword, ...rest } = payload;
    axios
      .post("/api/auth/register", JSON.stringify(rest))
      .then((response) => {
        if (!response?.data && response?.data?.errors) {
          console.log("register failed");
          if (response?.data?.errors[0]?.message) {
            toast.error(response?.data?.errors[0]?.message);
          } else {
            toast.error("Something went wrong");
          }
        }

        if (response?.data) {
          setIsLoading(false);
          toast.success("Account created successfully");
          onUpdateUser({ ...data, id: response?.data?.data?.userId });
          history.push("/register-school");
          // if (data.enable_2fa) {
          //   history.push("/register/verify");
          // } else {
          //   history.push("/login");
          // }
        } else {
          console.log("error");
          setIsLoading(false);
        }
      })
      .catch((error) => {
        if (error?.response && error?.response?.data) {
          toast.error(error?.response?.data?.errors);
        }
        setIsLoading(false);
      });
  };

  return (
    <div className="min-h-[calc(100vh-64px)]  bg-gray-50 flex items-center justify-center px-4 py-8">
      <div className="max-w-2xl w-full bg-gray-100 p-8 rounded-xl shadow-sm border-gray-300 border">
        <div className="flex flex-col items-center mb-6">
          <div className="w-12 h-12 bg-[#702982] rounded-full flex items-center justify-center mb-4">
            <Lock className="w-6 h-6 text-white" />
          </div>
          <h1 className="text-4xl font-semibold">Register Account</h1>
          <p className="text-gray-900 text-center mt-2 text-2xl ">
            Register your contact details for your Topic Heroes Account.
          </p>
        </div>

        <form onSubmit={handleSubmit(onSubmit)} className="space-y-4">
          <div className="grid grid-cols-2 gap-4">
            <div>
              <label className="block text-[12px] font-medium text-[#702982]">
                First Name
              </label>
              <input
                {...register("first_name")}
                className="mt-1 block w-full border border-gray-300 rounded-xl px-3 py-2"
                placeholder="First Name"
              />
              {errors.firstName && (
                <p className="text-red-200 text-lg mt-1">
                  {errors?.first_name?.message}
                </p>
              )}
            </div>
            <div>
              <label className="block text-[12px] font-medium text-[#702982]">
                Last Name
              </label>
              <input
                {...register("last_name")}
                className="mt-1 block w-full border border-gray-300 rounded-xl px-3 py-2"
                placeholder="Last Name"
              />
              {errors.lastName && (
                <p className="text-red-200 text-lg mt-1">
                  {errors.last_name.message}
                </p>
              )}
            </div>
          </div>

          <div>
            <label className="block text-[12px] font-medium text-[#702982]">
              Email Address
            </label>
            <input
              {...register("email")}
              type="email"
              className="mt-1 block w-full border border-gray-300 rounded-xl px-3 py-2"
              placeholder="Email address"
            />
            {errors.email && (
              <p className="text-red-200 text-lg mt-1">
                {errors.email.message}
              </p>
            )}
          </div>

          <div>
            <label className="block text-[12px] font-medium text-[#702982]">
              Phone
            </label>
            <input
              {...register("mobile")}
              className="mt-1 block w-full border border-gray-300 rounded-xl px-3 py-2"
              placeholder="0700 000 000"
            />
            {errors.mobile && (
              <p className="text-red-200 text-lg mt-1">
                {errors?.mobile?.message}
              </p>
            )}
          </div>

          <div>
            <label className="block text-[12px] font-medium text-[#702982]">
              Set Password
            </label>
            <input
              {...register("password")}
              type="password"
              className="mt-1 block w-full border border-gray-300 rounded-xl px-3 py-2"
              placeholder="********"
            />
            {errors.password && (
              <p className="text-red-200 text-lg mt-1">
                {errors?.password?.message}
              </p>
            )}
          </div>

          <div>
            <label className="block text-[12px] font-medium text-[#702982]">
              Repeat Password
            </label>
            <input
              {...register("confirmPassword")}
              type="password"
              className="mt-1 block w-full border border-gray-300 rounded-xl px-3 py-2"
              placeholder="********"
            />
            {errors.confirmPassword && (
              <p className="text-red-200 text-lg mt-1">
                {errors?.confirmPassword?.message}
              </p>
            )}
          </div>

          <p className="text-lg text-gray-900">
            Your password must be at least 8 characters and contain an upper
            case letter and a number.
          </p>

          <div className="flex items-center gap-3 mt-2">
            <input
              {...register("enable_2fa")}
              type="checkbox"
              id="enable_2fa"
              className="h-5 w-5 rounded border-gray-300 text-primary-400 focus:ring-primary-400"
            />
            <label
              htmlFor="enable_2fa"
              className="text-[12px] mt-2 font-medium text-[#702982] cursor-pointer"
            >
              Enable 2FA
            </label>
          </div>
          <p className="text-lg mt-2 text-gray-900">
            2FA is an additional layer of security that requires you to verify
            your account with a code sent to your phone.
          </p>

          <button
            type="submit"
            className="flex justify-center items-center py-3 px-6 border border-primary-400 rounded-full shadow-sm text-2xl font-medium text-white bg-primary-400 hover:bg-white hover:text-primary-400 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary w-full h-20 mt-8 hover:bg-primary-300"
          >
            {isLoading && (
              <Spinner
                animation="border"
                role="status"
                className="mr-2"
                size="lg mx-4"
              />
            )}
            Register
          </button>

          <div className="flex justify-between text-sm mt-4 border-t border-gray-300 pt-4">
            <Link to="/">
              <button
                type="button"
                className="text-gray-900 text-lg hover:text-gray-800"
              >
                Cancel
              </button>
            </Link>
            <button
              type="button"
              className="text-gray-900 text-lg hover:text-gray-800"
            >
              Need help with this?
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}
