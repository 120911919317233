import React, { Component } from "react";
import axios from "../apis/thresource";
import { Lock } from "lucide-react";
import Loader from "../components/Loader";
import toast from "react-hot-toast";

class Login extends Component {
  constructor(props) {
    super(props);
    this.props.onHideHeader();
    this.state = {
      email: "",
      password: "",
      emailCodeSent: false,
      checkingToken: false,
      isLoading: false,
    };
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    
    this.setState({ checkingToken: true }, () => {
      this.onToggleCheckToken();
      this.pollCancelToken = setInterval(this.onToggleCheckToken, 25500);
    });
  }

  componentWillUnmount() {
    if (this.pollCancelToken) {
      clearInterval(this.pollCancelToken);
    }
  }

  onToggleCheckToken = () => {
    this.props.requestCheckToken();
    this.setState({ checkingToken: false });
  };

  onChangeEmail = (e) => {
    this.setState({ email: e.target.value });
  };

  onChangePassword = (e) => {
    this.setState({ password: e.target.value });
  };

  onSubmitForm = (e) => {
    e.preventDefault();
    this.props.onClearAlert();
    this.setState({ isLoading: true });
    const postData = {
      email: this.state.email,
      password: this.state.password,
    };

    axios
      .post("/api/auth/login", JSON.stringify(postData))
      .then(async (response) => {
        this.setState({ isLoading: false });
        if (!response.data.success && response.data.errors) {
          toast.error(response.data.errors[0]?.message);
        }
        if (response.data.success) {
          const user = { ...response.data.data };
          this.props.onUpdateUser(user);

          // TODO: update auth remove this line. this is hack
          //if (true) {
          if (response.data.data.is2FAEnabled) {
            this.props.onUpdateAuthToken(response.data.data.token);
            await this.sendVerificationCodeSMS(user.mobile);
            this.setState({ isLoading: false });
            this.props.history.push("/login/verify");
          } else {
            this.props.onUpdateAuth(response.data.data.token, true);
          }
        }
      })
      .catch((error) => {
        this.setState({ isLoading: false });
        if (error.response && error.response.data) {
          toast.error(error.response.data.errors[0]?.message);
        }
      });
  };

  sendVerificationCodeSMS = async (mobileNumber) => {
    try {
      const response = await axios.post("/api/Twilio/SendVerificationCodeSMS", {
        mobile_number: mobileNumber,
      });

      if (response.data.success) {
        console.log("Verification code sent successfully:", response.data);
        // Handle success response
      } else {
        console.error(
          "Failed to send verification code:",
          response.data.errors
        );
        // Handle failure response
      }
    } catch (error) {
      console.error("Error sending verification code:", error);
      if (error.response && error.response.data) {
        console.error("Server error response:", error.response.data.errors);
        // Handle server error response
      }
    }
  };

  onRequestReset = () => {
    this.props.history.push("/forgot-password");
  };

  navigateToRegister = () => {
    this.props.history.push("/register");
  };

  onGoToChildLogin = () => {
    this.props.onNavigateToClientPage(`/login-child`);
  };

  render() {
    const { email, password, emailCodeSent, checkingToken } = this.state;

    if (this.state.isLoading) {
      return <Loader />;
    }

    return (
      <div className="min-h-screen flex items-center justify-center p-4">
        <div className="bg-gray-100 p-4 sm:p-6 md:p-8 rounded-xl shadow-md w-full max-w-[95%] sm:max-w-md md:max-w-lg lg:max-w-xl">
          <div className="text-center mb-6 sm:mb-8">
            <div className="inline-block p-2 sm:p-3 rounded-full bg-white border border-gray-300 mb-4">
              <Lock className="w-10 h-10 sm:w-12 sm:h-12 text-primary-400" />
            </div>
            <h2 className="text-4xl sm:text-3xl font-bold text-black-100 mb-3">
              Secure login
            </h2>
            <p className="text-2xl text-gray-900 font-lato leading-relaxed">
              Enter your email address and password to login. Once you have
              entered your credentials you will be prompted to authenticate your
              account by code.
            </p>
          </div>

          <form onSubmit={this.onSubmitForm} className="space-y-5">
            <div>
              <label
                htmlFor="email"
                className="block text-lg font-medium text-primary-400 mb-2"
              >
                Email Address
              </label>
              <input
                id="email"
                type="email"
                className="block w-full px-4 py-3 h-16  text-2xl border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-primary focus:border-primary"
                placeholder="name@domain.co.uk"
                value={email}
                onChange={this.onChangeEmail}
                required
              />
            </div>

            <div>
              <label
                htmlFor="password"
                className="block text-lg font-medium text-primary-400 mb-2"
              >
                Password
              </label>
              <input
                id="password"
                type="password"
                className="block w-full px-4 py-3 h-16 text-2xl border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-primary focus:border-primary"
                placeholder="Enter your password"
                value={password}
                onChange={this.onChangePassword}
                required
              />
            </div>

            <button
              type="submit"
              className="w-full flex justify-center hover:opacity-80 items-center py-4 px-6 border border-primary-400 rounded-full shadow-sm text-xl font-medium text-white bg-primary-400 hover:bg-white hover:text-primary-400 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary transition-colors duration-200 h-14 sm:h-16"
            >
              Sign In
            </button>
          </form>

          <div className="mt-6 sm:mt-8 flex flex-col sm:flex-row items-center justify-between gap-4 sm:gap-0">
            <button
              onClick={this.onRequestReset}
              className="text-base sm:text-lg text-gray-900 hover:underline font-medium"
            >
              Forgotten Password?
            </button>
            <button
              onClick={this.navigateToRegister}
              className="text-base sm:text-lg text-gray-900 hover:underline font-medium"
            >
              Register
            </button>
          </div>
        </div>
      </div>
    );
  }
}

export default Login;
