
import React, { Component } from 'react';
import { Link, NavLink as RRNavLink } from 'react-router-dom';
import {
  Nav,
  NavItem,
  NavLink,
  Row,
  Col,
  Card,
  CardTitle,
  CardText,
  Button
} from 'reactstrap';


class AdminIndex extends Component {

    render() {

        return (
          <div className="content-container bgc-grade-snowfall pall-l">
            <div className="content-mw p-banner">
              <h1 className="hw-font t-purple t-ctr spc-b-r">Admin Area</h1>
              <p className="mw-660 t-ctr no-spc">
                Manage Templates, filters, users and orders here.
              </p>
            </div>

            <div className="d-grid col-2-1-1 th-gap-l content-mw">
              <div className="d-flex flex-col flex-jc-sb pall-l th-panel th-br bgc-white">
                <div className="spc-b-l">
                  <h2 className="hw-font spc-b-s">Templates</h2>

                  <p>
                    Manage the templates that are displayed on the app here.
                  </p>
                </div>

                <div>
                  <Link className="blank-link" to={"/templates"}>
                    <div className="d-flex-inln th-admin-btn">
                      Manage Templates
                    </div>
                  </Link>
                </div>
              </div>

              <div className="d-flex flex-col flex-jc-sb pall-l th-panel pall-l th-br bgc-white">
                <div className="spc-b-l">
                  <h2 className="hw-font spc-b-s">Filters</h2>

                  <p>
                    Manage the filters for the app. Add new filters or edit
                    existing types.
                  </p>
                </div>

                <div>
                  <Link className="blank-link" to={"/filters"}>
                    <div className="d-flex-inln th-admin-btn">
                      Manage Filters
                    </div>
                  </Link>
                </div>
              </div>

              <div className="d-flex flex-col flex-jc-sb pall-l th-panel pall-l th-br bgc-white">
                <div className="spc-b-l">
                  <h2 className="hw-font spc-b-s">Sponsors</h2>

                  <p>Manage the sponsors associated with Topic Heroes</p>
                </div>

                <div>
                  <Link className="blank-link" to={"/sponsors"}>
                    <div className="d-flex-inln th-admin-btn">
                      Manage Sponsors
                    </div>
                  </Link>
                </div>
              </div>

              {/* <div className='d-flex flex-col flex-jc-sb pall-l th-panel pall-l th-br bgc-white'>

                        <h2 className='hw-font spc-b-s'>Users</h2>

                        <p className='spc-b-m'>Manage all users here.</p>

                        <Link className='blank-link' to={ "/users" }><div className='d-flex-inln th-admin-btn'>Manage Users</div></Link>

                    </div> */}

              <div className="d-flex flex-col flex-jc-sb pall-l th-panel pall-l th-br bgc-white">
                <div className="spc-b-l">
                  <h2 className="hw-font spc-b-s">Schools</h2>
                  <p>Manage the schools associated with Topic Heroes</p>
                </div>

                <div>
                  <Link className="blank-link" to={"/schools"}>
                    <div className="d-flex-inln th-admin-btn">
                      Manage Schools
                    </div>
                  </Link>
                </div>
              </div>

              <div className="d-flex flex-col flex-jc-sb pall-l th-panel pall-l th-br bgc-white">
                <div className="spc-b-l">
                  <h2 className="hw-font spc-b-s">Orders</h2>
                  <p>Manage and view orders created in Topic Heroes</p>
                </div>

                <div>
                  <Link className="blank-link" to={"/admin/orders"}>
                    <div className="d-flex-inln th-admin-btn">
                      Manage Orders
                    </div>
                  </Link>
                </div>
              </div>

              <div className="d-flex flex-col flex-jc-sb pall-l th-panel pall-l th-br bgc-white">
                <div className="spc-b-l">
                  <h2 className="hw-font spc-b-s">Categories</h2>
                  <p>Manage the template categories</p>
                </div>

                <div>
                  <Link className="blank-link" to={"/categories"}>
                    <div className="d-flex-inln th-admin-btn">
                      Manage Categories
                    </div>
                  </Link>
                </div>
              </div>

              <div className="d-flex flex-col flex-jc-sb pall-l th-panel pall-l th-br bgc-white">
                <div className="spc-b-l">
                  <h2 className="hw-font spc-b-s">Registrations</h2>
                  <p>Manage the registrations of schools</p>
                </div>

                <div>
                  <Link className="blank-link" to={"/registrations"}>
                    <div className="d-flex-inln th-admin-btn">
                      Manage Registrations
                    </div>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        );
    }
}

export default AdminIndex;