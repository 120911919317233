import React, { useState, useEffect } from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Folders, Users, Heart } from "lucide-react";
import CurrentProjectsTab from "./HomeTabs/CurrentProjectsTab";
import ChildAccountsTab from "./HomeTabs/ChildAccountsTab";
import FavoritesTab from "./HomeTabs/FavoritesTab";
import HomeCarousel from "./HomeCarousel";
import LoggedOutView from "./HomeTabs/LoggedOutView";
import {
  ROLE_TYPE_CHILD,
  ROLE_TYPE_TEACHER,
  ROLE_TYPE_USER,
} from "../../constants/role_constants";
import { checkAquiredRole } from "../../utils/Utils";

const Home = (props) => {
  const [activeTab, setActiveTab] = useState("projects");

  const {
    templateCatalouge,
    isLoggedIn,
    requestChangeFavouriteTemplate,
    requestTemplateDetails,
    savedBooks,
    onNavigateToClientPage,
    user,
    auth,
    onetimeCode,
    onShowHeader,
    onHideFavorite,
    onUpdateActiveSavedBook,
    onToggleEditorMode,
    requestOneTimeCode,
    onHideHeader,
    clearOnetimeCode,
    getRequestHeaders,
    requestSavedBooks,
    requestAddChildrenBulk,
  } = props;

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  React.useEffect(() => {
    onShowHeader();
    onHideFavorite(false);
  }, []);

  if (!isLoggedIn) {
    return <LoggedOutView />;
  }

  if (onetimeCode) {
    return (
      <OneTimeCodeView
        onetimeCode={onetimeCode}
        clearOnetimeCode={clearOnetimeCode}
        onShowHeader={onShowHeader}
      />
    );
  }

  return (
    <div className="max-w-full mx-auto bg-gray-100 rounded-lg shadow-md">
      <h2 className="text-2xl font-bold mb-4 pt-4 max-w-[90%] mx-auto">
        Featured Topics
      </h2>
      <HomeCarousel
        features={templateCatalouge.filter((template) => template.featured)}
      />
      <div className="flex flex-col max-w-[65%] mx-auto pb-16">
        <div className="flex flex-col sm:flex-row justify-between items-center mb-4 mt-8">
          <h1 className="text-3xl sm:text-4xl font-bold mb-4 sm:mb-0">
            Teacher Dashboard
          </h1>
          <div className="flex flex-wrap justify-center sm:justify-start space-x-0 sm:space-x-4 space-y-2 sm:space-y-0">
            <TabButton
              icon={Folders}
              label="My Projects"
              tabName="projects"
              activeTab={activeTab}
              setActiveTab={setActiveTab}
            />
            <TabButton
              icon={Users}
              label="My Class List"
              tabName="accounts"
              activeTab={activeTab}
              setActiveTab={setActiveTab}
            />
            <TabButton
              icon={Heart}
              label="My Favorites"
              tabName="favorites"
              activeTab={activeTab}
              setActiveTab={setActiveTab}
            />
          </div>
        </div>

        <hr className="border-t border-gray-300 mb-8" />

        {activeTab === "projects" && (
          <CurrentProjectsTab
            savedBooks={savedBooks}
            onSelectSavedBook={onUpdateActiveSavedBook}
            onNavigateToClientPage={onNavigateToClientPage}
            getRequestHeaders={getRequestHeaders}
            isChild={checkAquiredRole([ROLE_TYPE_CHILD], auth)}
            isParentOrTeacher={checkAquiredRole(
              [ROLE_TYPE_USER, ROLE_TYPE_TEACHER],
              auth
            )}
            requestSavedBooks={requestSavedBooks}
            onToggleEditorMode={onToggleEditorMode}
          />
        )}
        {activeTab === "accounts" && (
          <ChildAccountsTab
            user={user}
            onNavigateToClientPage={onNavigateToClientPage}
            onUpdateChildDetail={props.onUpdateChildDetail}
            requestOneTimeCode={requestOneTimeCode}
            onHideHeader={onHideHeader}
            clearOnetimeCode={clearOnetimeCode}
            onShowHeader={onShowHeader}
            isTeacher={checkAquiredRole([ROLE_TYPE_TEACHER], auth)}
            isParent={checkAquiredRole([ROLE_TYPE_USER], auth)}
            requestAddChildrenBulk={requestAddChildrenBulk}
          />
        )}
        {activeTab === "favorites" && (
          <FavoritesTab
            templateCatalouge={templateCatalouge}
            isLoggedIn={isLoggedIn}
            onNavigateToClientPage={onNavigateToClientPage}
            requestTemplateDetails={requestTemplateDetails}
            requestChangeFavouriteTemplate={requestChangeFavouriteTemplate}
          />
        )}
      </div>
    </div>
  );
};

const TabButton = ({ icon: Icon, label, tabName, activeTab, setActiveTab }) => (
  <button
    onClick={() => setActiveTab(tabName)}
    className={`flex items-center px-4 py-2 rounded-full border ${
      activeTab === tabName
        ? "bg-white border-gray-300 text-gray-900"
        : "bg-gray-300 text-gray-900"
    }`}
  >
    <Icon
      className={`w-6 h-6 mr-2 ${
        activeTab === tabName ? "text-purple-200" : "text-gray-900"
      }`}
    />
    {label}
  </button>
);

export default Home;
