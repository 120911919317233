import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { Lock } from "lucide-react";
import { Link } from "react-router-dom";
import axios from "../../apis/thresource";
import toast from "react-hot-toast";
import { useEffect, useState, useCallback } from "react";
import Spinner from "react-bootstrap/Spinner";
import AddressAutoCompleteController from "../../components/auto-complete/AutoComplete";
import { getRequestHeadersFromAuth } from "../../utils/Utils";
import _ from "lodash";

const schema = yup.object({
  title: yup.string().required("School name is required"),
  postcode: yup.string().required("Postcode is required"),
  address_line_1: yup.string().required("Address line 1 is required"),
  address_line_2: yup.string(),
  county: yup.string(),
  city: yup.string().required("City is required"),
  class_name: yup.string(),
});

export default function RegisterSchool({
  onUpdateUser,
  onHideHeader,
  history,
  chosenSchool,
  user,
  auth,
}) {
  const {
    register,
    control,
    handleSubmit,
    formState: { errors },
    setValue,
    clearErrors,
    setError,
    watch,
  } = useForm({
    resolver: yupResolver(schema),
  });

  const [isLoading, setIsLoading] = useState(false);
  const [isSchoolNameValid, setIsSchoolNameValid] = useState(0);
  const watchValues = watch(["title", "postcode", "city"]);
  const [previousWatchValues, setPreviousWatchValues] = useState([]);

  const validateEntryAPI = async (title, postcode, city) => {
    const response = await axios.post(
      "/api/Schools/GetSchoolByName",
      {
        title: title,
        postcode: postcode,
        city: city,
      },
      getRequestHeadersFromAuth(auth)
    );

    return response;
  };

  const debouncedValidation = useCallback(
    _.debounce(async (title, postcode, city) => {
      if (title === "") {
        return;
      }
      const response = await validateEntryAPI(title, postcode, city);
      if (response?.data?.data?.length > 0) {
        setError("title", {
          type: "manual",
          message: "This school already exists.",
        });
        setIsSchoolNameValid(2);
      } else {
        clearErrors("title");
        setIsSchoolNameValid(1);
      }
    }, 500),
    []
  );

  useEffect(() => {
    if (Array.isArray(watchValues) && watchValues.length > 0) {
      const [title = "", postcode = "", city = ""] = watchValues;

      if (
        previousWatchValues[0] !== title ||
        previousWatchValues[1] !== postcode ||
        previousWatchValues[2] !== city
      ) {
        debouncedValidation(title, postcode, city);
        setPreviousWatchValues(watchValues);
      }
    }
  }, [watchValues, debouncedValidation, previousWatchValues]);

  useEffect(() => {
    console.log("chosenSchool: ", chosenSchool);
    if (chosenSchool) {
      setValue("title", chosenSchool.name);
      setValue("postcode", chosenSchool.postcode);
      setValue("address_line_1", chosenSchool.address_line_1);
      setValue("address_line_2", chosenSchool.address_line_2);
      setValue("country", chosenSchool.country);
      setValue("county", chosenSchool.county);
      setValue("city", chosenSchool.city);
    }
  }, [chosenSchool]);

  const registerSchool = async (payload) => {
    console.log("registering school");
    let payloadd = {
      ...payload,
      country: "United Kingdom",
      user_id: user?.id,
    };
    return await axios.post("/api/Schools/Register", JSON.stringify(payloadd));
  };

  const assignSchoolToUser = async (payload) => {
    return await axios.post(
      "/api/Schools/AssignToUser",
      JSON.stringify(payload)
    );
  };

  const onSubmit = async (data) => {
    console.log("data: ", data);
    try {
      setIsLoading(true);
      const response = await registerSchool(data);
      console.log("response: ", response);
      setIsLoading(false);
      if (response?.data?.success) {
        toast.success("School registered successfully");
        if (response?.data?.data && response?.data?.success) {
          history.push("/register-success");
          // const assignResponse = await assignSchoolToUser({
          //   school_id: response?.data?.data,
          //   user_id: user?.id,
          // });
          // console.log("assignResponse: ", assignResponse);
          // if (assignResponse?.data?.success) {
          //   history.push("/register-success");
          //   // toast.success("School assigned to user successfully");
          // } else {
          //   // toast.error("Something went wrong");
          // }
        } else {
          toast.error("Something went wrong");
        }
      }
    } catch (error) {
      console.log("error: ", error);
      toast.error("Something went wrong");
      setIsLoading(false);
    }
  };

  return (
    <div className="min-h-[calc(100vh-64px)]  bg-gray-50 flex items-center justify-center px-4 py-8">
      <div className="max-w-4xl w-full bg-gray-100 p-8 rounded-xl shadow-sm border-gray-300 border">
        <div className="flex flex-col items-center mb-6">
          <div className="w-12 h-12 bg-[#702982] rounded-full flex items-center justify-center mb-4">
            <Lock className="w-6 h-6 text-white" />
          </div>
          <h1 className="text-4xl font-semibold">Register School</h1>
          <p className="text-gray-900 text-center mt-2 text-2xl">
            Please register your school information below. Make sure this
            information is accurate, you will be unable to change this after
            registration.
          </p>
        </div>

        <form onSubmit={handleSubmit(onSubmit)} className="space-y-4">
          <div className="grid grid-cols-1 gap-4">
            <div>
              <label className="block text-[12px] font-medium text-[#702982]">
                Postcode
              </label>
              <AddressAutoCompleteController
                control={control}
                name="postcode"
                error={errors.postcode}
                onLookUpAddressSelect={(address) => {
                  console.log("address: ", address);
                  setValue("address_line_1", address?.address_line_1);
                  setValue("address_line_2", address?.address_line_2);
                  setValue("county", address?.county);
                  setValue("city", address?.city);
                }}
              />
              {errors.postcode && (
                <p className="text-red-200 text-lg mt-1">
                  {errors?.postcode?.message}
                </p>
              )}
            </div>
            <div>
              <label className="block text-[12px] font-medium text-[#702982]">
                School Name
              </label>
              <input
                {...register("title")}
                className="mt-1 block w-full border border-gray-300 rounded-xl px-3 py-2"
                placeholder="School Name"
              />
              {isSchoolNameValid === 1 && (
                <p className="text-green-300 text-lg mt-1 font-medium">
                  You can register this school.
                </p>
              )}
              {errors.title && (
                <p className="text-red-200 text-lg mt-1">
                  {errors.title.message}
                </p>
              )}
            </div>
          </div>

          {/* <div>
            <label className="block text-[12px] font-medium text-[#702982]">
              Email Address
            </label>
            <input
              {...register("email")}
              type="email"
              className="mt-1 block w-full border border-gray-300 rounded-xl px-3 py-2"
              placeholder="Email address"
            />
            {errors.email && (
              <p className="text-red-200 text-lg mt-1">
                {errors.email.message}
              </p>
            )}
          </div> */}

          <div>
            <label className="block text-[12px] font-medium text-[#702982]">
              Address Line 1
            </label>
            <input
              {...register("address_line_1")}
              className="mt-1 block w-full border border-gray-300 rounded-xl px-3 py-2"
              placeholder="Address Line 1"
            />
            {errors.address_line_1 && (
              <p className="text-red-200 text-lg mt-1">
                {errors?.address_line_1?.message}
              </p>
            )}
          </div>

          <div>
            <label className="block text-[12px] font-medium text-[#702982]">
              Address Line 2
            </label>
            <input
              {...register("address_line_2")}
              className="mt-1 block w-full border border-gray-300 rounded-xl px-3 py-2"
              placeholder="Address Line 2"
            />
            {errors.address_line_2 && (
              <p className="text-red-200 text-lg mt-1">
                {errors?.address_line_2?.message}
              </p>
            )}
          </div>

          <div>
            <label className="block text-[12px] font-medium text-[#702982]">
              City
            </label>
            <input
              {...register("city")}
              className="mt-1 block w-full border border-gray-300 rounded-xl px-3 py-2"
              placeholder="City"
            />
            {errors.city && (
              <p className="text-red-200 text-lg mt-1">
                {errors?.city?.message}
              </p>
            )}
          </div>

          <div>
            <label className="block text-[12px] font-medium text-[#702982]">
              County
            </label>
            <input
              {...register("county")}
              className="mt-1 block w-full border border-gray-300 rounded-xl px-3 py-2"
              placeholder="County"
            />
            {errors.county && (
              <p className="text-red-200 text-lg mt-1">
                {errors?.county?.message}
              </p>
            )}
          </div>

          <button
            type="submit"
            disabled={isLoading}
            onClick={async () => {
              if (chosenSchool) {
                const assignResponse = await assignSchoolToUser({
                  school_id: response?.data?.data,
                  user_id: user?.id,
                });
                if (assignResponse?.data?.success) {
                  toast.success("School assigned to user successfully");
                  history.push("/register-success");
                } else {
                  toast.error("Something went wrong");
                }
              } else {
                handleSubmit(onSubmit)();
              }
            }}
            className="flex justify-center items-center py-3 px-6 border border-primary-400 rounded-full shadow-sm text-2xl font-medium text-white bg-primary-400 hover:bg-white hover:text-primary-400 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary w-full h-20 mt-8 hover:bg-primary-300"
          >
            {isLoading && (
              <Spinner
                animation="border"
                role="status"
                className="mr-2"
                size="lg mx-4"
              />
            )}
            {chosenSchool ? "Confirm" : "Register"}
          </button>

          <div className="flex justify-between text-sm mt-4 border-t border-gray-300 pt-4">
            <Link to="/">
              <button
                type="button"
                className="text-gray-900 text-lg hover:text-gray-800"
              >
                Cancel
              </button>
            </Link>
            <Link to="/registered-schools">
              <button
                type="button"
                className="text-gray-900 text-lg hover:text-gray-800"
              >
                Has your school already registered?
              </button>
            </Link>
          </div>
        </form>
      </div>
    </div>
  );
}
