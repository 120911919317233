import React, { useState } from "react";
import ProjectItem from "../../../components/ProjectItem";

const CurrentProjectsTab = ({
  savedBooks,
  onSelectSavedBook,
  onNavigateToClientPage,
  getRequestHeaders,
  isChild,
  isParentOrTeacher,
  requestSavedBooks,
  onToggleEditorMode,
}) => {
  const [showAll, setShowAll] = useState(false);
  const savedBookCount = savedBooks ? savedBooks.length : 0;
  const displayedBooks = showAll ? savedBooks : savedBooks.slice(0, 3);

  const onCreateProjectClick = () => {
    onNavigateToClientPage("/topics");
  };

  return (
    <div className="section-block bg-transparent">
      <h2 className="text-2xl font-bold mb-4">
        Showing {displayedBooks.length} of {savedBookCount} saved Projects
      </h2>
      {savedBookCount < 1 ? (
        <div>
          <p className="mb-2">You currently have no saved projects</p>
          <p className="mb-4">
            {isParentOrTeacher
              ? "Once your child begins their writing journey, you can view and edit their saved projects here."
              : "Once you begin your writing journey, your saved projects will be shown here."}
          </p>
          <button className="btn btn-primary" onClick={onCreateProjectClick}>
            Browse Topics
          </button>
        </div>
      ) : (
        <div className="flex justify-between items-center mb-4">
          <p>This is where your current topic projects are shown.</p>
          {savedBookCount > 3 && (
            <button
              className="text-lg font-medium text-primary-300 hover:underline"
              onClick={() => setShowAll(!showAll)}
            >
              {showAll ? "See Less" : "See More"}
            </button>
          )}
        </div>
      )}

      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-16">
        {displayedBooks.map((project, index) => (
          <ProjectItem
            key={index}
            project={project}
            onSelectSavedBook={onSelectSavedBook}
            onNavigateToClientPage={onNavigateToClientPage}
            getRequestHeaders={getRequestHeaders}
            isChild={isChild}
            isParentOrTeacher={isParentOrTeacher}
            requestSavedBooks={requestSavedBooks}
          />
        ))}
      </div>
    </div>
  );
};

export default CurrentProjectsTab;
