import React from "react";

const RegistrationSuccess = ({ user, history }) => {
  console.log("user: ", user);
  return (
    <div className="min-h-[calc(100vh-64px)]  bg-white flex items-center justify-center px-4">
      <div className="max-w-2xl w-full text-center items-center justify-center space-y-4">
        <h1 className="text-4xl font-semibold text-black">
          Thanks for registering with us, {user?.first_name}
        </h1>
        <p className="text-black text-[18px] mx-auto max-w-lg">
          You have entered a new school address that we need to validate. You
          will receive an email once this is approved.
        </p>

        <button
          onClick={() => history.push("/login")}
          className="justify-center items-center py-3 px-6 border border-primary-400 rounded-full shadow-sm text-2xl font-medium text-white bg-primary-400 hover:bg-white hover:text-primary-400 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary w-[60%] h-18 mt-12 hover:bg-primary-300"
        >
          Follow Admin Journey
        </button>
      </div>
    </div>
  );
};

export default RegistrationSuccess;
