import BasketItem from "../../components/basket-list/BasketItem";
import CheckoutSummary from "../../components/basket-list/CheckoutSummery";
import { connect } from "react-redux";
import { requestFetchBasket } from "../../store/actions";
import { useState, useEffect } from "react";
import axios from "../../apis/thresource";

const Checkout = ({
  requestFetchBasket,
  getRequestHeaders,
  basket,
  requestRemoveFromBasket,
  onApplyVoucher,
  voucherApplied,
  onCheckoutClick,
  orderRedeemed,
  handlePlaceOrder,
  isLoading,
}) => {
  console.log("isLoading", isLoading);
  const [currentOrderRedeemed, setCurrentOrderRedeemed] = useState(null);
  const { school, user } = currentOrderRedeemed || {};
  const job_order_items = currentOrderRedeemed?.job_order_items || [];
  const getCurrentOrder = () => {
    axios
      .get(`/api/Basket/GetCurrentOrder`, getRequestHeaders())
      .then((response) => {
        console.log("response", response);
        setCurrentOrderRedeemed(response.data.data);
      });
  };

  useEffect(() => {
   //setCurrentOrderRedeemed(orderRedeemed);
    getCurrentOrder();
    console.log("orderRedeemed", orderRedeemed);
  }, [orderRedeemed]);

  return (
    <div className="min-h-screen bg-gray-50 py-8 ">
      <div className="max-w-8xl px-4 sm:px-6 lg:px-8 lg:mx-[10%] xl:mx-[15%]">
        <h1 className="text-4xl sm:text-5xl font-bold mb-4 sm:mb-0">
          Checkout order
        </h1>
        {/* <p className="text-3xl sm:text-2xl font-bold mb-4 sm:mb-0 text-gray-900">
          {currentOrderRedeemed?.items?.length > 0
            ? currentOrderRedeemed?.items?.length === 1
              ? "You have 1 item in your basket."
              : `You have ${currentOrderRedeemed?.items?.length} items in your basket.`
            : "Your basket is empty."}
        </p> */}

        <h2 className="text-3xl font-semibold text-black mb-4">
          Order Details
        </h2>

        <div className="mt-8 grid gap-8 lg:grid-cols-3 ">
          <div className="lg:col-span-2 space-y-6">
            {/* Order Details Section */}
            <div>
              <div className="shadow-sm p-12 bg-gray-100 border border-gray-200 rounded-xl">
                <div className="grid md:grid-cols-2 gap-8">
                  <div>
                    <h3 className="font-medium text-black mb-4  leading-8">
                      Order No: {currentOrderRedeemed?.order_id}
                    </h3>
                    <div className="space-y-2">
                      <h4 className="font-medium text-black">
                        Contact Information
                      </h4>
                      <p className="text-gray-900">
                        {user?.firstName} {user?.lastName}
                      </p>
                      <p className="text-gray-900">{user?.email}</p>
                      <p className="text-gray-900">{user?.phoneNumber}</p>
                    </div>
                  </div>
                  <div className="lg:pl-32">
                    <h3 className="font-medium text-black mb-4">
                      Delivery Address
                    </h3>
                    <div className="space-y-2 text-gray-900">
                      <p>{school?.name}</p>
                      <p>Class: {school?.class}</p>
                      <p>{school?.addressLine1}</p>
                      <p>{school?.addressLine2}</p>
                      <p>{school?.city}</p>
                      <p>{school?.county}</p>
                      <p>{school?.postCode}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* Order Items Section */}
            <div>
              <h2 className="text-xl font-semibold text-gray-900 mb-4">
                Order Items
              </h2>
              <div className="space-y-4">
                {job_order_items?.map((item) => (
                  <BasketItem
                    key={item.id}
                    item={{book_name: item.title, quantity: item.quantity, price: item.price, ...item}}
                    isCheckout={true}
                    onItemRemoveClick={() => {
                      // setIsRemoveBasketModalOpen(true);
                      // setSelectedItem(item);
                    }}
                  />
                ))}
              </div>
            </div>
          </div>

          {/* Order Summary Section */}
          <div className="lg:col-span-1">
            <CheckoutSummary
              onApplyVoucher={onApplyVoucher}
              onCheckout={() => {
                navigate("/checkout");
              }}
              voucherApplied={voucherApplied}
              isBasketEmpty={!basket}
              onCheckoutClick={onCheckoutClick}
              handlePlaceOrder={handlePlaceOrder}
              isLoading={isLoading}
              voucherCode={currentOrderRedeemed?.school?.voucher_code}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  basket: state.basket,
});

export default connect(mapStateToProps, { requestFetchBasket })(Checkout);
