import React from "react";
import { Controller } from "react-hook-form";
import { useAsyncList } from "@react-stately/data";
import { useState } from "react";

const AddressAutoCompleteController = ({
  control,
  name,
  error,
  onLookUpAddressSelect,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  let list = useAsyncList({
    async load({ signal, filterText }) {
      if (filterText && /^[a-zA-Z]/.test(filterText)) {
        try {
          let res = await fetch(
            `https://api.postcodes.io/postcodes/${filterText}/autocomplete`,
            { signal }
          );
          let json = await res.json();

          if (!json?.result) {
            return {
              items: [],
            };
          }

          const data = json?.result?.map((it) => {
            return {
              name: it,
            };
          });

          return {
            items: data,
          };
        } catch (error) {
          console.error("Error fetching autocomplete data:", error);
          return {
            items: [],
          };
        }
      } else {
        return { items: [] };
      }
    },
  });

  const onPostCodeSelect = async (postcode) => {
    const response = await fetch(
      `https://api.postcodes.io/postcodes/${postcode}`
    );
    const data = await response?.json();
    const { result } = data;

    return {
      city: result?.admin_district,
      state: result?.region,
      postcode: result?.postcode,
      address_line_1: result?.address_line_1,
      address_line_2: result?.address_line_2,
      county: result?.county ?? result?.pfa,
      country:
        result?.country === "England" ? "United Kingdom" : result?.country,
    };
  };

  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { onChange, value } }) => (
        <div className="relative">
          <input
            type="text"
            value={value}
            onChange={(e) => {
              const newValue = e?.target?.value;
              list?.setFilterText(newValue);
              onChange(newValue);
              if (!isOpen) {
                setIsOpen(true);
              }
            }}
            className={`border p-2 w-full rounded-md ${
              error ? "border-red-500" : "border-gray-300"
            }`}
            placeholder="Enter postcode"
          />
          {isOpen && list?.items?.length > 0 && (
            <ul className="absolute mt-1 w-full bg-white border border-gray-300 rounded-md shadow-lg z-10">
              {list?.items?.map((item) => (
                <li
                  key={item.name}
                  className="p-2 ml-2 border-b border-gray-200 hover:bg-gray-100 cursor-pointer"
                  onClick={async () => {
                    onChange(item.name);
                    const formData = await onPostCodeSelect(item.name);
                    onLookUpAddressSelect(formData);
                    setIsOpen(false);
                    console.log("close");
                  }}
                >
                  {item.name}
                </li>
              ))}
            </ul>
          )}
          {error && (
            <p className="text-red-500 text-sm mt-1">{error.message}</p>
          )}
        </div>
      )}
    />
  );
};

export default AddressAutoCompleteController;
