import { Link } from "react-router-dom";
import { useEffect } from "react";
const LoggedOutView = ({ isBtnHover, onHoverEffect }) => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className="min-h-[calc(100vh-64px)] \ flex items-center justify-center bg-background px-4 ">
      <div className="max-w-2xl mx-auto text-center space-y-6">
        <h1 className="text-3xl font-bold tracking-tight sm:text-4xl lg:text-5xl leading-10">
          Learning Adventures for Children
        </h1>
        <p className="text-muted-foreground text-2xl font-semibold  mx-auto">
          Topic Heroes® is an easy to use online resource that lets children
          write and create their own professionally printed books.
        </p>
        <div className="flex gap-4 justify-center">
          <Link className="w-full" to="/register-account">
            <button className="flex justify-center items-center py-3 px-6 border border-primary-400 rounded-full shadow-sm text-2xl font-medium text-white bg-primary-400 hover:bg-white hover:text-primary-400 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary w-full h-20 mt-8 hover:bg-primary-300">
              {" "}
              Register
            </button>
          </Link>
          <Link className="w-full" to="/login">
            <button className="flex justify-center items-center py-3 px-6 border border-primary-400 rounded-full shadow-sm text-2xl font-medium text-white bg-primary-400 hover:bg-white hover:text-primary-400 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary w-full h-20 mt-8 hover:bg-primary-300">
              {" "}
              Login
            </button>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default LoggedOutView;
