const BasketItem = ({ item, index, onItemRemoveClick = () => {} }, isCheckout = false) => {
  const { book_name, author, thumbnail_url } = item;
  return (
    <div key={index} className="flex items-center gap-4 rounded-lg  p-4 mb-4  bg-gray-100 shadow-md h-32">
      <img
        src={thumbnail_url}
        alt={book_name}
        className="h-24 w-24 flex-shrink-0 rounded-md bg-gray-100"
      />
      <div className="flex-grow">
        <h3 className="font-semibold text-black">{book_name}</h3>
        <p className="text-[12px] text-gray-900 mt-2">{author}</p>
      </div>
      {!isCheckout && (
        <button
          onClick={() => onItemRemoveClick(item)}
        className="text-[12px] text-gray-900 font-semibold hover:text-purple-200 transition-colors"
      >
        Remove Item
        </button>
      )}
    </div>
  );
};

export default BasketItem;
