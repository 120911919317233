import React, { Component } from "react";
import { NavLink as RRNavLink, Link } from 'react-router-dom';
import {
  Form
} from 'reactstrap';
import { BASEURL } from "../apis/thresource";
import { ROLE_TYPE_ADMIN, ROLE_TYPE_CHILD, ROLE_TYPE_USER, ROLE_TYPE_TEACHER } from "../constants/role_constants";
import { checkAquiredRole } from "../utils/Utils";

class FlyMenu extends Component {

    constructor(props) {
        super(props);

        this.onItemClick = this.onItemClick.bind(this);
        this.onLogoutClick = this.onLogoutClick.bind(this);
        this.onClickEditor = this.onClickEditor.bind(this);
        this.onLoginBypassClick = this.onLoginBypassClick.bind(this);
    }

    onItemClick() {
        this.props.onToggleMenu();
    }

    onLogoutClick() {
        this.props.onLogout();
        this.props.onToggleMenu();
    }

    onClickEditor(){
        this.props.onToggleEditorMode();
        this.props.onToggleMenu();
    }

    onLoginBypassClick() {
        this.props.requestLoginBypass();
    }

    render() {
        var visibility = "app-menu--hide";
     
        if (this.props.menuVisibility) {
          visibility = "app-menu--show";
        }

        const isChild = checkAquiredRole([ ROLE_TYPE_CHILD ], this.props.auth);
        const isAdmin = checkAquiredRole([ ROLE_TYPE_ADMIN ], this.props.auth);
        const isUser = checkAquiredRole([ ROLE_TYPE_USER ], this.props.auth);
        const isTeacher = checkAquiredRole([ ROLE_TYPE_TEACHER ], this.props.auth);
        const URL = BASEURL();

        return (
        <div onMouseDown={ this.props.handleMouseDown} className={`app-menu ${visibility}` }>

            

                <div className="app-menu-content">

                    <h1 className="app-menu-content__title">Menu</h1>
                    <Link className="app-menu-content__link" to="/" tag={RRNavLink} onClick={ this.onItemClick } ><i className="fa-light fa-house-blank fa-fw"></i>Home</Link>

                    <Link className="app-menu-content__link" to="/topics" tag={RRNavLink}  onClick={ this.onItemClick }><i className="fa-light fa-book-open-cover fa-fw"></i>Browse topics</Link>
                
                    {
                        (this.props.isLoggedIn === true) ? (

                            <div>
                                {
                                    (isChild === false) ? (
                                        <div>
                                            <Link className="app-menu-content__link" to="/basket" tag={RRNavLink}  onClick={ this.onItemClick }><i className="fa-light fa-shopping-cart fa-fw"></i>Basket</Link>
                                            <Link className="app-menu-content__link" to="/user/details" tag={RRNavLink} onClick={ this.onItemClick } ><i className="fa-light fa-user fa-fw"></i>My account</Link>
                                        </div>
                                    ) : null
                                }
                                {/* {  
                                    (isChild === false) ? (
                                        <div>
                                            <Link className="app-menu-content__link" to="/user/childaccounts" tag={RRNavLink}  onClick={ this.onItemClick }><i className="fa-light fa-pencil fa-fw"></i>Child accounts</Link>

                                                                    

                                            <Link className="app-menu-content__link" to="/order-history" tag={RRNavLink}  onClick={ this.onItemClick }><i className="fa-light fa-file fa-fw"></i>View orders</Link> 
                                        </div>
                                    ) : null
                                } */}
                                
                                {
                                    (this.props.isLoggedIn === true && isAdmin) ? (
                                        <div>
                                                <Link className="app-menu-content__link" to="/admin/index" tag={RRNavLink}  onClick={ this.onItemClick }><i className="fa-light fa-screwdriver-wrench fa-fw"></i>Admin</Link>
                                            {/* <NavItem>
                                                <NavLink to="/editor" tag={RRNavLink} onClick={ this.onClickEditor } >Editor</NavLink>
                                            </NavItem> */}
                                        </div>
                                    ) : null
                                }

                                <Link className="app-menu-content__link" to="/" tag={RRNavLink}  onClick={ this.onLogoutClick }><i className="fa-light fa-arrow-right-to-bracket fa-fw"></i>Logout</Link>
                                
                            </div>

                        ) : (

                            <div>

                                <Link className="app-menu-content__link" to="/Login" tag={RRNavLink}  onClick={ this.onItemClick } ><i className="fa-light fa-arrow-right-to-bracket fa-fw"></i>Sign in</Link>

                                <Link className="app-menu-content__link" to="/Register" tag={RRNavLink}  onClick={ this.onItemClick } ><i className="fa-light fa-pen-to-square fa-fw"></i>Register</Link>
                                
                                {/* <Form action={ `${URL}/api/auth/loginbypass` } method="post"  >

                                    <button className="app-menu-content__link"><i class="fa-light fa-unlock fa-fw"></i>Login bypass</button>
                                        
                                </Form> */}                                

                            </div>
                            
                        )
                    } 
                    
                    
                    {/* <NavItem>
                        <NavLink to="/about" tag={RRNavLink} onClick={ this.onItemClick }>About</NavLink>
                    </NavItem>
                    <NavItem>
                        <NavLink to="/dev" tag={RRNavLink}  onClick={ this.onItemClick } >Dev</NavLink>
                    </NavItem>
                    <NavItem>
                        <NavLink to="/faq" tag={RRNavLink}  onClick={ this.onItemClick }>FAQ</NavLink>
                    </NavItem>
                    <NavItem>
                        <NavLink to="/help" tag={RRNavLink}  onClick={ this.onItemClick }>Help</NavLink>
                    </NavItem> */}
                    

                    {/* <UncontrolledDropdown nav inNavbar>
                        <DropdownToggle nav caret  >
                            Admin Area
                        </DropdownToggle>
                        <DropdownMenu right >
                            <DropdownItem>
                                <NavLink to="/login" tag={RRNavLink}>Login</NavLink>
                            </DropdownItem>
                            <DropdownItem>
                                <NavLink to="/register" exact tag={RRNavLink}>register</NavLink>
                            </DropdownItem>
                            <DropdownItem divider />
                            <DropdownItem>
                                <NavLink to="/templates" tag={RRNavLink}>Templates</NavLink>
                            </DropdownItem>
                            <DropdownItem>
                                <NavLink to="/filters" tag={RRNavLink}>Filters</NavLink>
                            </DropdownItem>
                            <DropdownItem divider />
                            <DropdownItem>
                                <NavLink to="/" tag={RRNavLink}>Logout</NavLink>
                            </DropdownItem>
                        </DropdownMenu>
                    </UncontrolledDropdown> */}

                </div>

                <div className="app-menu-footer">
                    
                    <p className="app-menu-footer__text"><strong>Topic Heroes</strong><sup>™</sup> 17 London House, High Street, Stony Stratford, Buckinghamshire MK11 1SY. United Kingdom </p>

                    <p className="app-menu-footer__text">+44 (0) 1908 560 049 | <Link class="app-menu-footer__link" href="mailto:info@topicheroes.co.uk">info@topicheroes.co.uk</Link></p>

                    <p className="app-menu-footer__text">©Topic Heroes<sup>™</sup> 2023. All rights reserved.</p>

                </div>

            

            
          
        </div>

        );

      }
      
}
 
export default FlyMenu;
