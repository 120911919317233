import React, { useState } from "react";
import Spinner from "react-bootstrap/Spinner";
import axios from "axios";

const CheckoutSummary = ({
  onApplyVoucher = () => {},
  voucherApplied = false,
  isBasketEmpty = false,
  isLoading = false,
  handlePlaceOrder,
  voucherCode = "XXX-XXX-XXX",
}) => {
  const [isAgreed, setIsAgreed] = useState(false);


  const handleCheckboxChange = () => {
    setIsAgreed((prev) => !prev);
  };

  const handleOnPlaceOrder = () => {

    handlePlaceOrder();
  };

  return (
    <div className="rounded-lg bg-gray-100 p-6 shadow-sm border border-gray-50">
      <h2 className="text-3xl font-semibold text-black">Order Summary</h2>
      <p className="mt-2 text-gray-900">
        Please make sure your order details are correct. Your items will be
        delivered via a Royal Mail Signed for service. The labelling will
        include your class name.
      </p>

      <hr className="border-t border-gray-300 mt-6 pt-2" />

      <div id="voucher-code" className="mb-2">
        <label className="text-lg font-medium text-purple-200">
          School Voucher Code
        </label>
        <input
          type="text"
          value={voucherCode}
          readOnly
          className="mt-1 w-full rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 bg-gray-200"
        />
      </div>

      <div className="mt-4 flex items-center bg-white p-4 text-[12px] rounded-lg shadow-md transition-transform transform hover:scale-105">
        <input
          type="checkbox"
          checked={isAgreed}
          onChange={handleCheckboxChange}
          className="mr-2"
        />
        <span className="text-gray-900">
          You must agree to our Terms and Conditions before placing your order.
        </span>
      </div>
      {isAgreed && (
        <>
          <hr className="border-t border-gray-300 mt-6 pt-4" />

          <button
            onClick={handleOnPlaceOrder && handleOnPlaceOrder}
            disabled={ !isAgreed}
            className={` w-full h-16 rounded-full px-4 py-2 text-[16px] transition-colors ${
              !isAgreed
                ? "bg-gray-300 text-gray-900 cursor-not-allowed"
                : "bg-purple-200 text-white hover:opacity-80"
            }`}
          >
            {isLoading && (
              <Spinner
                animation="border"
                role="status"
                className="mr-2"
                size="lg"
              />
            )}
            Place your order
          </button>
        </>
      )}
    </div>
  );
};

export default CheckoutSummary;
