import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { formatDate } from "../../../utils/Utils";
import axios from "../../../apis/thresource";
import { getRequestHeadersFromAuth } from "../../../utils/Utils";
import Spinner from "react-bootstrap/Spinner";
import { toast } from "react-hot-toast";
const SchoolDetailsApprove = ({ auth, history }) => {
  const { state } = useLocation();
  const [registrationData, setRegistrationData] = useState(null);
  console.log("registrationData: ", state);
  const [isApproving, setIsApproving] = useState(false);
  const [isDeclining, setIsDeclining] = useState(false);

  useEffect(() => {
    console.log("registrationData: ", state);
    setRegistrationData(state);
  }, [state]);

  const handleApprove = async () => {
    setIsApproving(true);
    try {
      const response = await axios.post(
        "/api/Reviews/ApproveSchoolRegistration",
        {
          user_id: registrationData?.user?.id,
          school_id: registrationData?.id,
          authorize_reason: "Approved by admin",
        },
        getRequestHeadersFromAuth(auth)
      );
      console.log("response: ", response);
      if (response?.data?.success) {
        toast.success("Registration approved");
        history.push("/schools");
      } else {
        toast.error("Error approving registration");
      }
      setIsApproving(false);
    } catch (error) {
      setIsApproving(false);
      console.log("Error approving registration: ", error);
    }
  };

  const handleDecline = async () => {
    setIsDeclining(true);
    try {
      const response = await axios.post(
        "/api/Reviews/DeclineSchoolRegistration",
        {
          user_id: registrationData?.user?.id,
          school_id: registrationData?.id,
          authorize_reason: "Declined by admin",
        },
        getRequestHeadersFromAuth(auth)
      );
      if (response?.data?.success) {
        toast.success("Registration declined");
      } else {
        toast.error("Error declining registration");
      }
      setIsDeclining(false);
    } catch (error) {
      setIsDeclining(false);
      console.log("Error declining registration: ", error);
    }
  };

  return (
    <div className="container min-h-screen mx-auto p-6 mt-8">
      <div className="bg-white shadow-md rounded-lg max-w-8xl mx-auto">
        <div className="p-6">
          <h1 className="text-4xl font-semibold mt-8">Registration details</h1>
          <p className="text-gray-900 mb-6 text-[16px]">
            Please check over this registration and approve or decline
          </p>
          <div className="grid md:grid-cols-3 gap-6">
            {/* Date and User Information */}
            <div className="space-y-6">
              <div>
                <h3 className="font-semibold mb-2">Date</h3>
                <p className="text-gray-900 text-[14px]">
                  {formatDate(registrationData?.request_date)}
                </p>
              </div>
              <div>
                <h3 className="font-semibold mb-2">User Information</h3>
                <div className="space-y-1 text-gray-900 text-[14px]">
                  <p>
                    {registrationData?.user?.firstName +
                      " " +
                      registrationData?.user?.lastName}
                  </p>
                  <p>{registrationData?.user?.email}</p>
                  <p>{registrationData?.user?.phoneNumber}</p>
                </div>
              </div>
            </div>

            {/* School Information */}
            <div>
              <h3 className="font-semibold mb-2">School Information</h3>
              <div className="space-y-1 text-gray-900 text-[14px]">
                <p>{registrationData?.name}</p>
                <p>{registrationData?.className}</p>
                <p>{registrationData?.address_line_1}</p>
                <p>{registrationData?.address_line_2}</p>
                <p>{registrationData?.city}</p>
                <p>{registrationData?.county}</p>
                <p>{registrationData?.postcode}</p>
              </div>
            </div>

            {/* Action Buttons */}
            <div className="space-y-6">
              <h3 className="font-semibold mb-2">Approval</h3>
              <button
                className="w-full h-16 bg-purple-200 hover:bg-purple-100 text-white text-[14px] font-bold py-2 px-4 rounded-full transition duration-300 ease-in-out"
                onClick={() => handleApprove()}
              >
                {isApproving && (
                  <Spinner
                    animation="border"
                    role="status"
                    className="mr-2"
                    size="lg mx-4"
                  />
                )}
                Approve Registration
              </button>
              <button
                className="w-full h-16 bg-white hover:bg-gray-100 text-gray-800 text-[14px] font-semibold py-2 px-4 rounded-full border border-gray-400 transition duration-300 ease-in-out"
                onClick={() => handleDecline()}
              >
                {isDeclining && (
                  <Spinner
                    animation="border"
                    role="status"
                    className="mr-2"
                    size="lg mx-4"
                  />
                )}
                Decline Registration
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SchoolDetailsApprove;
