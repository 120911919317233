import {
  ROLE_TYPE_ADMIN,
  ROLE_TYPE_CHILD,
  ROLE_TYPE_USER,
  ROLE_TYPE_TEACHER,
} from "../constants/role_constants";

export function deleteSiteCookie() {
  document.cookie = "cookie_accepted=; expires=Thu, 01 Jan 1970 00:00:01 GMT;";
}

export function setSiteCookie() {
  document.cookie = "cookie_accepted=true; path=/";
}

export function getSiteCookie() {
  var name = "cookie_accepted=";
  var ca = document.cookie.split(";");
  for (var i = 0; i < ca.length; i++) {
    var c = ca[i];
    while (c.charAt(0) === " ") c = c.substring(1);
    if (c.indexOf(name) !== -1) return c.substring(name.length, c.length);
  }
  return "";
}

export function cleanURL(url) {
  return url.replace(/\?.*$/, "").replace(/\/[^\/]*$/, "") + "/";
}

// export function checkAquiredRole(rolesRequired, auth) {
//   var roleAquired = false;
//   for (var j = 0; j < rolesRequired.length; j++) {
//     if (auth !== null && auth.roles !== undefined && auth.roles != null) {
//       for (var k = 0; k < auth.roles.length; k++) {
//         if (auth.roles[k] === rolesRequired[j]) {
//           roleAquired = true;
//           break;
//         }
//       }
//     }
//   }
//   return roleAquired;
// }

export function checkAquiredRole(rolesRequired, auth) {
  var roleAquired = false;
  const roles = auth?.roles;
  if (roles) {
    for (let i = 0; i < rolesRequired.length; i++) {
      for (let j = 0; j < roles.length; j++) {
        if (rolesRequired[i] === 1 && roles.includes("Admin")) {
          roleAquired = true;
        } else if (rolesRequired[i] === 3 && roles.includes("Child")) {
          roleAquired = true;
        } else if (rolesRequired[i] === 2 && roles.includes("User")) {
          roleAquired = true;
        } else if (rolesRequired[i] === 4 && roles.includes("Teacher")) {
          roleAquired = true;
        }
      }
    }
  }

  return roleAquired;
}

export function scrollTo(y) {
  try {
    document.scrollingElement.scrollTop = y;
  } catch (e) {
    console.log("scrollTo", e);
  }
}

export function formatDate(_date) {
  var today = new Date(_date);
  var dd = today.getDate();
  var mm = today.getMonth() + 1; //January is 0!
  var yyyy = today.getFullYear();
  var curHour =
    today.getHours() > 12
      ? today.getHours() - 12
      : today.getHours() < 10
      ? "0" + today.getHours()
      : today.getHours();
  var curMinute =
    today.getMinutes() < 10 ? "0" + today.getMinutes() : today.getMinutes();
  var curSeconds =
    today.getSeconds() < 10 ? "0" + today.getSeconds() : today.getSeconds();
  var curMeridiem = today.getHours() > 12 ? "PM" : "AM";

  if (dd < 10) {
    dd = "0" + dd;
  }

  if (mm < 10) {
    mm = "0" + mm;
  }
  return (
    dd +
    "/" +
    mm +
    "/" +
    yyyy +
    " " +
    curHour +
    ":" +
    curMinute +
    ":" +
    curSeconds +
    curMeridiem
  );
}

export function toggleSearchFilterOptionChange(
  searchFilterCatalouge,
  _optionId
) {
  for (var i = 0; i < searchFilterCatalouge.length; i++) {
    if (
      searchFilterCatalouge[i].options &&
      searchFilterCatalouge[i].options.length > 0
    ) {
      for (var j = 0; j < searchFilterCatalouge[i].options.length; j++) {
        if (searchFilterCatalouge[i].options[j].id === _optionId) {
          searchFilterCatalouge[i].options[j].selected =
            !searchFilterCatalouge[i].options[j].selected;
          break;
        }
      }
    }
  }
}

export function toggleSearchFilterOptionChangeByTitle(
  searchFilterCatalouge,
  selectedValue,
  _sfid
) {
  for (var i = 0; i < searchFilterCatalouge.length; i++) {
    if (
      searchFilterCatalouge[i].id === _sfid &&
      searchFilterCatalouge[i].options &&
      searchFilterCatalouge[i].options.length > 0
    ) {
      for (var j = 0; j < searchFilterCatalouge[i].options.length; j++) {
        if (searchFilterCatalouge[i].options[j].title === selectedValue) {
          searchFilterCatalouge[i].options[j].selected = true;
        } else {
          searchFilterCatalouge[i].options[j].selected = false;
        }
      }
      break;
    }
  }
}

export function rebuildSearchFilterCatalouge(
  templateDetail,
  searchFilterCatalouge
) {
  //Build the search filter catalogue.
  if (searchFilterCatalouge !== null) {
    for (var i = 0; i < searchFilterCatalouge.length; i++) {
      //loop through search filters
      if (
        searchFilterCatalouge[i].options &&
        searchFilterCatalouge[i].options.length > 0
      ) {
        for (var j = 0; j < searchFilterCatalouge[i].options.length; j++) {
          //loop through each filters options
          var selected = false;

          if (templateDetail !== null && templateDetail.options !== null) {
            for (var k = 0; k < templateDetail.options.length; k++) {
              //find option in template
              if (
                templateDetail.options[k].id ===
                searchFilterCatalouge[i].options[j].id
              ) {
                selected = true;
              }
            }
          }

          searchFilterCatalouge[i].options[j].selected = selected;
        }
      }
    }
  }
  return searchFilterCatalouge;
}

export function getRequestHeadersFromAuth(_auth) {
  //_token
  if (_auth === undefined || _auth === null) {
    return null;
  }
  if (_auth.token === undefined) {
    return null;
  }

  if (_auth.token === null) {
    return null;
  }

  if (_auth.token.length > 0) {
    const bearerToken = `Bearer ${_auth.token}`;
    return { headers: { Authorization: bearerToken } };
  }

  return null;
}

export function formatSchoolAddress(address) {
  const { address_line_1, address_line_2, city, county, postcode, country } =
    address;

  // Create address parts array with proper spacing
  const addressParts = [
    address_line_1 && address_line_2
      ? `${address_line_1}, ${address_line_2}`
      : address_line_1 || address_line_2,
    city && county ? `${city}, ${county}` : city || county,
    postcode,
    country,
  ];

  // Filter out empty/null values and join with commas and spaces
  return addressParts.filter(Boolean).join(", ");
}
