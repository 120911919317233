import React from "react";

const CheckoutSuccess = ({ user, history }) => {
  console.log("user: ", user);
  return (
    <div className="min-h-[calc(100vh-64px)]  bg-white flex items-center justify-center px-4">
      <style>
        {`
          @keyframes slide-up {
            0% {
              transform: translateY(20px);
              opacity: 0;
            }
            100% {
              transform: translateY(0);
              opacity: 1;
            }
          }
          .slide-up {
            animation: slide-up 0.5s ease-out forwards;
          }
        `}
      </style>

      <div className="max-w-2xl w-full text-center items-center justify-center space-y-4 bg-gray-200 p-12 rounded-xl border border-gray-300 slide-up">
        <h1 className="text-4xl font-semibold text-black">
          Thank you, {user?.first_name}
        </h1>
        <p className="text-black text-[18px] mx-auto max-w-lg">
          Your order has been received successfully. you can check on your order
          status in My account.
        </p>

        <div className="flex items-center justify-center gap-4">
          <button
            onClick={() => history.push("/user/details")}
            className="justify-center items-center py-3 px-6 border border-gray-600 rounded-full shadow-sm text-2xl font-medium text-gray-900 bg-white hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary w-[60%] h-18 mt-12"
          >
            View Orders
          </button>

          <button
            onClick={() => history.push("/")}
            className="justify-center items-center py-3 px-6 border border-primary-400 rounded-full shadow-sm text-2xl font-medium text-white bg-primary-400 hover:bg-white hover:text-primary-400 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary w-[60%] h-18 mt-12 hover:bg-primary-300"
          >
            Back to Home
          </button>
        </div>
      </div>
    </div>
  );
};

export default CheckoutSuccess;
