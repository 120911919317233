import BasketItem from "../../components/basket-list/BasketItem";
import OrderSummary from "../../components/basket-list/BasketSummery";
import { useEffect, useState } from "react";
import { requestFetchBasket } from "../../store/actions";
import { connect } from "react-redux";
import Loader from "../../components/Loader";
import RemoveBasketModal from "../../components/basket-list/RemoveBasketModal";
import axios from "../../apis/thresource";
import { getRequestHeadersFromAuth } from "../../utils/Utils";
import toast from "react-hot-toast";
const Basket = ({
  requestFetchBasket,
  getRequestHeaders,
  basket,
  requestRemoveFromBasket,
  onApplyVoucher,
  voucherApplied,
  onCheckoutClick,
  history,
  user,
  auth,
  isLoading: isLoadingBasket,
}) => {
  const [isLoading, setisLoading] = useState(true);
  const [isRemoveBasketModalOpen, setIsRemoveBasketModalOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [teacherSchool, setTeacherSchool] = useState(null);
  console.log("isLoadingBasket", isLoadingBasket);

  useEffect(() => {
    setisLoading(true);
    requestFetchBasket(getRequestHeaders());
  }, [requestFetchBasket, getRequestHeaders]);

  useEffect(() => {
    if (basket) {
      setisLoading(false);
      getCurrentOrder();
    }
  }, [basket]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const getTeacherSchool = async () => {
    return axios.post(
      "/api/Schools/GetSchoolForUser",
      { user_id: user?.id },
      getRequestHeadersFromAuth(auth)
    );
  };

  const getCurrentOrder = () => {
    axios
      .get(`/api/Basket/GetCurrentOrder`, getRequestHeaders())
      .then((response) => {
        console.log("response", response);
        if (response.data.data) {
          if (response.data.data.order_id) {
            history.push(`/checkout`);
          }
        }
      });
  };

  useEffect(() => {
    const fetchTeacherSchool = async () => {
      const response = await getTeacherSchool();
      console.log("response school: ", response);
      if (response.data) {
        setTeacherSchool(response.data);
      }
    };
    fetchTeacherSchool();
  }, []);

  const assignNewVoucherCode = async () => {
    const response = await axios.post(
      "/api/Reviews/AssignVoucherCodeToSchoolUser",
      {
        user_id: user?.id,
        school_id: teacherSchool?.data?.id,
        authrize_reson: "Test",
      },
      getRequestHeadersFromAuth(auth)
    ).then((response) => {
      console.log("response assign new voucher code: ", response);
      if (response.status === 200) {
        toast.success(
           "New voucher code assigned successfully, please copy the code from My Account -> My School Tab"
        );
      } else {
        toast.error("Failed to assign new voucher code");
      }
    });
  };

  return (
    <div className="min-h-screen bg-gray-50 py-8">
      <style>
        {`
          @keyframes slide-up {
            0% {
              transform: translateY(20px);
              opacity: 0;
            }
            100% {
              transform: translateY(0);
              opacity: 1;
            }
          }
          .slide-up {
            animation: slide-up 0.5s ease-out forwards;
          }
        `}
      </style>
      <div className="max-w-8xl px-4 sm:px-6 lg:px-8 lg:mx-[10%]">
        <h1 className="text-4xl sm:text-5xl font-bold mb-4 sm:mb-0">
          Your Basket
        </h1>

        <p className="text-3xl sm:text-2xl font-bold mb-4 sm:mb-0 text-gray-900">
          {basket?.items?.length > 0
            ? basket?.items?.length === 1
              ? "You have 1 item in your basket."
              : `You have ${basket?.items?.length} items in your basket.`
            : "Your basket is empty."}
        </p>

        <div className="mt-8 grid gap-8 lg:grid-cols-3">
          <div className="lg:col-span-2 space-y-4 slide-up">
            {isLoading ? (
              <div className="flex justify-center items-center h-[50vh]">
                <Loader />
              </div>
            ) : (
              <>
                {basket?.items?.map((item) => (
                  <BasketItem
                    key={item.id}
                    item={item}
                    onItemRemoveClick={() => {
                      console.log("Remove item:", item);
                      setIsRemoveBasketModalOpen(true);
                      setSelectedItem(item);
                    }}
                  />
                ))}
                {basket?.items?.length === 0 && (
                  <div className="slide-up">
                    <div className="flex flex-col justify-center items-center h-[50vh]">
                      <p>
                        Your basket is empty. Please add some items to your
                        basket.
                      </p>
                      <button
                        onClick={() => history.push("/topics")}
                        className="btn btn--sml bg-primary-400 hover:bg-primary-300 text-white mt-4"
                      >
                        Browse Topics
                      </button>
                    </div>
                  </div>
                )}
              </>
            )}
          </div>

          <div className="lg:col-span-1">
            <OrderSummary
              onApplyVoucher={(code) => {
                onApplyVoucher(code);
                // setTimeout(() => {
                //   assignNewVoucherCode();
                // }, 3000);
              }}
              onCheckout={() => {
                navigate("/checkout");
              }}
              voucherApplied={voucherApplied}
              isBasketEmpty={!basket || basket?.items?.length === 0}
              onCheckoutClick={onCheckoutClick}
              isLoading={isLoadingBasket}
              onGetCode={() => {
                assignNewVoucherCode();
              }}
            />
          </div>
        </div>
      </div>
      <RemoveBasketModal
        open={isRemoveBasketModalOpen}
        onClose={() => setIsRemoveBasketModalOpen(false)}
        onConfirmDelete={() => {
          console.log("Confirm delete:", selectedItem);
          setIsRemoveBasketModalOpen(false);
          setisLoading(true);
          requestRemoveFromBasket(selectedItem.id);
        }}
      />
    </div>
  );
};

const mapStateToProps = (state) => ({
  basket: state.basket,
});

export default connect(mapStateToProps, { requestFetchBasket })(Basket);
