import React, { useEffect, useState } from "react";
import axios from "../../../apis/thresource";
import { Link } from "react-router-dom";
import { formatSchoolAddress } from "../../../utils/Utils";
import { getRequestHeadersFromAuth } from "../../../utils/Utils";

const Registrations = ({ history, auth, onSelectSchoolForApproval }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [registrations, setRegistrations] = useState([]);
  const getRegistrations = async () => {
    setIsLoading(true);
    const response = await axios.get(
      "/api/Schools/GetMappedSchools",
      getRequestHeadersFromAuth(auth)
    );
    console.log("GetMappedSchoolsForUser", response);
    setIsLoading(false);
    if (response?.data?.success) {
      setRegistrations(response?.data?.data);
    }
  };
  useEffect(() => {
    window.scrollTo(0, 0);
    setIsLoading(true);
    getRegistrations();
  }, []);
  return (
    <div className="min-h-screen bg-gray-50 p-8">
      <div className="mx-auto max-w-7xl bg-gray-200 rounded-xl shadow-sm border-gray-300 border p-8">
        <h1 className="text-4xl font-semibold mt-8">
          New Registrations for Approval
        </h1>
        <p className="mb-6 text-[16px] text-gray-900 mt-6">
          You have {registrations?.length} school registration
          {registrations?.length !== 1 ? "s" : ""} for approval
        </p>

        <ul className="space-y-4">
          {registrations?.map((registration) => (
            <li
              key={registration.id}
              className="bg-white rounded-lg shadow-sm p-6"
            >
              <div className="space-y-2">
                <h2 className="text-[14px] font-semibold text-black">
                  {registration.name}
                </h2>
                <p className="text-lg text-gray-800">
                  {formatSchoolAddress(registration)}
                </p>
                <Link
                  to={{
                    pathname: `/registrations/details`,
                    state: registration ,
                  }}
                  className="inline-block text-lg font-medium text-primary hover:text-primary/80"
                >
                  View Details
                </Link>
              </div>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default Registrations;
