import React, { useState, useEffect } from "react";
import axios from "../../apis/thresource";
const schoolsData = [
  {
    id: 1,
    name: "St. Mary's School",
    address: "123 Education Lane, Riverside, Central County, PC1 2AB",
    isChosen: false,
  },
  {
    id: 2,
    name: "Wellington Academy",
    address: "45 Learning Road, Hillside, East County, PC2 3CD",
    isChosen: true,
  },
  {
    id: 3,
    name: "Oakwood High School",
    address: "789 Knowledge Street, Meadowview, West County, PC3 4EF",
    isChosen: false,
  },
  {
    id: 4,
    name: "Riverside Elementary",
    address: "321 Wisdom Avenue, Lakeside, South County, PC4 5GH",
    isChosen: false,
  },
];

export default function RegisteredSchoolsList({
  onUpdateChosenSchool,
  history,
}) {
  const [searchTerm, setSearchTerm] = useState("");
  const [schools, setSchools] = useState([]);
  const [chosenSchool, setChosenSchool] = useState(null);
  const getSchools = async () => {
    const response = await axios.get("/api/Schools/GetAll");
    console.log(response.data?.data);
    setSchools(response.data?.data);
  };

  useEffect(() => {
    getSchools();
  }, []);
  console.log("schools: ", schools);
  const filteredSchools =
    Array.isArray(schools) &&
    schools?.filter(
      (school) =>
        school?.name?.toLowerCase()?.includes(searchTerm?.toLowerCase()) ||
        school?.address_line_1
          ?.toLowerCase()
          ?.includes(searchTerm?.toLowerCase())
    );

  console.log("chosenSchool: ", chosenSchool);

  return (
    <div className="min-h-[calc(100vh-64px)]  bg-gray-50 flex items-center justify-center px-4 py-12">
      <div className="max-w-7xl w-full mx-auto p-4 md:p-6 bg-gray-100 border border-gray-200 rounded-lg">
        <div className="flex flex-col md:flex-row justify-between items-start md:items-center mb-6 gap-4">
          <div className="flex flex-col gap-2">
            <h1 className="text-4xl font-semibold">Registered Schools</h1>
            <h3 className="text-lg font-medium">
              {filteredSchools?.length} results found
            </h3>
          </div>
          <input
            type="text"
            placeholder="Search for your school"
            className="w-full md:w-64 lg:w-96 rounded-2xl px-4 py-2 border border-gray-200 focus:outline-none focus:ring-2 focus:ring-[#702982]"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
        </div>

        <div className="space-y-4 h-[calc(100vh-300px)] overflow-y-auto">
          {filteredSchools.map((school) => (
            <div
              key={school.id}
              className={`rounded-lg shadow-sm p-4 relative cursor-pointer transition-colors h-32 ${
                chosenSchool?.id === school.id
                  ? "bg-gray-300"
                  : "bg-white hover:bg-gray-400"
              }`}
              onClick={() => setChosenSchool(school)}
            >
              <div className="flex items-start gap-3">
                {school.isChosen ? (
                  <div className="w-3 h-3 rounded-full bg-[#702982] mt-2" />
                ) : (
                  <div className="w-3 h-3 rounded-full border border-gray-300 mt-2" />
                )}
                <div>
                  <h2 className="font-medium">
                    {school.name}
                    {/* {school.isChosen && (
                      <span className="text-sm font-normal text-gray-600 ml-2">
                        My Chosen School
                      </span>
                    )} */}
                  </h2>
                  <p className="text-gray-900 text-[16px] mt-1">
                    {school.address_line_1}, {school.address_line_2},
                    {school.city}, {school.county}, {school.postcode}
                  </p>
                </div>
              </div>
            </div>
          ))}
        </div>

        <div className="flex justify-between items-center mt-6">
          {/* <button className="bg-[#702982] text-white px-6 py-2 rounded-full hover:bg-[#702982]/90 transition-colors">
            Use this School
          </button> */}
          <button
            type="submit"
            disabled={!chosenSchool}
            onClick={() => {
              onUpdateChosenSchool(chosenSchool);
              history.push("/register-school");
            }}
            className="flex disabled:opacity-50 disabled:cursor-not-allowed justify-center items-center py-3 px-6 border border-primary-400 rounded-full shadow-sm text-2xl font-medium text-white bg-primary-400 hover:bg-white hover:text-primary-400 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary  h-18 mt-8 hover:bg-primary-300"
          >
            Use this School
          </button>

          <a
            href="/register-school"
            className="text-lg text-gray-900 hover:text-gray-800"
          >
            Register a new School
          </a>
        </div>
      </div>
    </div>
  );
}
